<template>
  <v-card rounded="xl" :class="isInactive ? 'inactive-card' : 'active-card'">
    <v-card-title class="justify-space-between text-body-1">
      <div class="d-flex align-center">
        <v-icon size="40" :color="mainColor" class="mr-4">mdi-gift-open-outline</v-icon>
        <v-icon size="24" :color="mainColor" class="mr-1">mdi-monitor</v-icon>
        <span>PCページ自動更新</span>
      </div>
      <div v-if="user.updating_item_info_started_at">
        更新日時: <span v-if="!isInactive">{{ time_ymdt_format(user.updating_item_info_started_at) }}</span>
      </div>
    </v-card-title>
    <v-divider />
    <v-tabs
      v-model="tab"
      fixed-tabs
      hide-slider
      color="white"
      active-class="black--text"
    >
      <v-tab
        v-for="(system, i) in systems" :key="i"
        :ripple="false"
        :class="tab === i ? 'text-black white' : 'grey lighten-2'"
      >
        <v-icon
          class="mr-2"
          :color="tab === i ? mainColor : ''"
          :style="system.descriptionKey === 'BySalesMethod' ? 'transform: rotate(180deg)' : ''"
        >
          mdi-comment-text-outline
        </v-icon>
        {{ system.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="system in systems" :key="system.descriptionKey">
        <operation-item-card-main
          :description-key="system.descriptionKey"
          :full-auto-column-name="system.fullAutoColumnName"
          :main-color="system.mainColor"
          :is-inactive="isInactive"
        />
      </v-tab-item>
    </v-tabs-items>

    <InactiveLayer
      v-if="isInactive"
      :is-show-option="isShowOptionButton"
      @changeOption="showOptionIndexModal = true"
    />

    <OptionIndexModal
      v-if="showOptionIndexModal"
      specified-option-code="for_pc"
      :user="user"
      @close_this_modal="showOptionIndexModal = false"
    />
  </v-card>
</template>

<script>
import timeUtilsMixin from "../../../../packs/mixins/time_utils";
import InactiveLayer from "../InactiveLayer.vue";
import OperationItemCardMain from "./Main.vue";
import OptionIndexModal from "../../../option/OptionIndexModal.vue";
export default {
  name: "OperationItemCardForPC",
  components: {
    InactiveLayer,
    OperationItemCardMain,
    OptionIndexModal
  },
  mixins: [timeUtilsMixin],

  data: () => ({
    tab: 0,
    mainColor: '#00736D',
    systems: [
      { title: 'PC用販売説明文', descriptionKey: 'BySalesMethod', fullAutoColumnName: 'is_full_auto_on_BySalesMethod', mainColor: '#00736D' },
      { title: 'PC用商品説明文', descriptionKey: 'ForPC', fullAutoColumnName: 'is_full_auto_on_ForPC', mainColor: '#00736D' }
    ],
    showOptionIndexModal: false
  }),

  computed: {
    user() {
      return this.$store.state.user;
    },

    isInactive() {
      return this.$store.getters.isFreePlan || this.$store.getters.isSilverContentsPlan || this.isShowOptionButton;
    },

    isShowOptionButton() {
      return this.$store.getters.isSilverItemPlan && !this.$store.getters.isEnableForPC;
    }
  }
}
</script>
