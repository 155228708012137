import { PLAN_ID } from "../const";

export const getters = {
  analysis_data: (state) => (effect_type) => {
    return state.analysis_data.find(f => f.effect_type === effect_type)
  },
  isEnableSummaryMail: (state) => {
    const target = state.user && state.user.shops_options.find((item) => item.option.code === 'summary_mail');
    return target && target.is_enabled;
  },
  isEnableShopTop: (state) => {
    const target = state.user && state.user.shops_options.find((item) => item.option.code === 'shop_top');
    return target && target.is_enabled;
  },
  isEnableForPC: (state) => {
    const target = state.user && state.user.shops_options.find((item) => item.option.code === 'for_pc');
    return target && target.is_enabled;
  },
  isFreePlan: (state) => {
    return state.user && state.user.plan.id === PLAN_ID.FREE;
  },
  isSilverPlan: (state) => {
    return state.user && (state.user.plan.id === PLAN_ID.SILVER || state.user.plan.id === PLAN_ID.SILVER_CONTENTS);
  },
  isSilverItemPlan: (state) => {
    return state.user && state.user.plan.id === PLAN_ID.SILVER;
  },
  isSilverContentsPlan: (state) => {
    return state.user && state.user.plan.id === PLAN_ID.SILVER_CONTENTS;
  },
  isGoldPlan: (state) => {
    return state.user && state.user.plan.id === PLAN_ID.GOLD;
  },
  isTrialPlan: (state) => {
    return state.user && state.user.plan.id === PLAN_ID.TRIAL;
  },
  isPlatinumPlan: (state) => {
    return state.user && state.user.plan.id === PLAN_ID.PLATINUM;
  },
  isAgencyChild: (state) => {
    return state.user && state.user["is_agency_child?"];
  }
}
