<template>
  <v-select
    :items="shopPageSystems"
    item-text="name"
    item-value="id"
    label="システム選択"
    dense
    outlined
    clearable
    hide-details
    @input="(item) => $emit('selectedSystem', item)"
  >
  </v-select>
</template>

<script>
export default {
  props: ["shopPageSystems"],
  name: "system-select",
};
</script>
