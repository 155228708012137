<template>
  <ecup-modal modal_width="600px" :force="true">
    <v-container class="pa-5">
      <v-row justify="center">
        <v-col cols="auto">
          現在のプランではご利用いただけません。
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <v-tooltip v-if="!canChangePlan()" bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                v-on="{ ...tooltip }"
                large
                color="primary"
                :ripple="false"
              >
                プラン変更
              </v-btn>
            </template>
            <span>担当販売店までご連絡ください</span>
          </v-tooltip>
          <v-btn
            v-else
            large
            color="primary"
            @click="$emit('showPlanIndexModal')"
          >
            プラン変更
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn large @click="$router.push({ name: 'Dashboard' })">
            ダッシュボードに戻る
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </ecup-modal>
</template>

<script>
import EcupModal from "../../components/EcupModal.vue";

export default {
  components: { EcupModal },
  props: ["user"],
  methods: {
    canChangePlan() {
      return !this.user["is_agency_child?"];
    },
  },
};
</script>

<style></style>
