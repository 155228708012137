<template>
  <v-container fluid class="pa-0" v-if="user">
    <v-row align="end" class="mb-5">
      <v-col cols="4" class="pr-1">
        <span class="display-1" style="white-space: nowrap">
          <v-icon left large color="success" class="dashboard-icon">
            mdi-view-dashboard-outline
          </v-icon>
          ダッシュボード
        </span>
      </v-col>
      <v-col cols="8" class="text-right d-flex align-center justify-end">
        <RLoginPasswordCard
          v-if="user.rms_registration_status === 3"
          :user="user"
          @fetchUser="fetch_user()"
        />
        <LicenseKeyCard :lisence_key_expired_at="user.lisence_key_expired_at" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <span class="text-h6">メイン機能の稼働状況</span>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="6">
        <OperationItemCardForSmartPhone />
      </v-col>
      <v-col cols="6">
        <OperationPageCardForContents />
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="12">
        <span class="text-h6">オプション機能の稼働状況</span>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="6">
        <OperationItemCardForPC />
      </v-col>
      <v-col cols="6">
        <OperationPageCardForTop />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <MailCard />
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="12">
        <span class="text-h6">その他情報</span>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="6">
        <v-row no-gutters>
          <v-col cols="12">
            <RecommendedActionCard
              :completed_actions="completed_actions"
              :uncomplete_actions="uncomplete_actions"
              :trial_ends_at="trial_ends_at"
              :user="user"
              @setUser="SET_USER($event)"
            />
          </v-col>
        </v-row>
        <v-row v-if="user.use_stripe" no-gutters class="mt-4">
          <v-col cols="12">
            <CouponCard
              :available_off_50_coupons_count="
                user.my_available_off_50_coupons_count
              "
              :available_off_10_coupons_count="
                user.my_available_off_10_coupons_count
              "
              :use_coupon="user.use_coupon"
              :shop_code="user.shop_code"
              @setUser="SET_USER($event)"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <NewsCard />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <SliderCard />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import timeUtilsMixin from "../../packs/mixins/time_utils";
import userUtilsMixin from "../../packs/mixins/user_utils";
import axios from "axios";
import { mapMutations } from "vuex";
import { SET_USER } from "store/mutation-types";

import NewsCard from "./components/NewsCard.vue";
import LicenseKeyCard from "./components/LicenseKeyCard.vue";
import RLoginPasswordCard from "./components/RLoginPasswordCard.vue";
import CouponCard from "./components/CouponCard.vue";
import RecommendedActionCard from "./components/RecommendedActionCard.vue";
import SliderCard from "./components/SliderCard.vue";
import OperationItemCardForSmartPhone from "./components/operation_item_card/ForSmartPhone.vue";
import OperationItemCardForPC from "./components/operation_item_card/ForPC.vue";
import OperationPageCardForTop from "./components/operation_page_card/ForTop.vue";
import OperationPageCardForContents from "./components/operation_page_card/ForContents.vue";
import MailCard from "./components/MailCard.vue";
import LiffLoginForm from "../components/liff/LiffLoginForm.vue";

export default {
  name: "DashboardPage",
  components: {
    NewsCard,
    LicenseKeyCard,
    RLoginPasswordCard,
    CouponCard,
    RecommendedActionCard,
    SliderCard,
    OperationItemCardForSmartPhone,
    OperationItemCardForPC,
    OperationPageCardForTop,
    OperationPageCardForContents,
    MailCard,
    LiffLoginForm,
  },

  mixins: [timeUtilsMixin, userUtilsMixin],

  async created() {
    this.fetch_user();
  },

  watch: {
    user: async function (newVal, oldVal) {
      if (newVal) {
        if (!this.user.completed_first_update_items) {
          this.$router.push({ name: "InstallationStatus" });
          return;
        }
        this.set_tools();

        this.set_recommended_action(newVal);
      }
    },
  },

  data() {
    return {
      tools: [],
      completed_actions: [],
      uncomplete_actions: [],
      trial_ends_at: "",
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async set_tools() {
      let tool_res = await axios.get(`/api/v1/tools/`);
      this.tools = tool_res.data.tools;
    },
    async set_recommended_action(user) {
      let res = await axios.get(`/api/v1/recommended_actions`);
      this.completed_actions = res.data.completed_actions;
      this.uncomplete_actions = res.data.uncomplete_actions;
      this.trial_ends_at = this.time_ymd_format(user.trial_ends_at);
    },

    ...mapMutations({
      SET_USER,
    }),
  },
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}

.v-card {
  height: 100%;
}

.col {
  padding: 8px;
}

.dashboard-icon::before {
  background: linear-gradient(135deg, #2286c1 0%, #92b125 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

<style lang="scss">
.active-card {
  .theme--light.v-tabs .v-tab--active:before,
  .theme--light.v-tabs .v-tab--active:hover:before {
    opacity: 0;
  }
}
.inactive-card {
  .v-card__title {
    background: rgba(0, 0, 0, 0.04);
  }
  .theme--light.v-tabs .v-tab--active:before,
  .theme--light.v-tabs .v-tab--active:hover:before {
    opacity: 1;
    background: rgba(0, 0, 0, 0.04);
  }
  .card-content {
    background: rgba(0, 0, 0, 0.04);
  }
}
</style>
