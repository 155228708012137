<template>
  <v-row>
    <v-col>
      <v-divider></v-divider>

      <v-row class="mt-2 mb-4">
        <v-col cols="10">
          <v-card-title class="pa-0">
            <v-row>
              <v-col cols="auto"> レイアウト・デザイン設定 </v-col>
              <v-col cols="auto" v-if="!isAvailablePremium()">
                <span class="v-align-baseline text-body-2">
                  *ゴールド以上のみ
                </span>
              </v-col>
            </v-row>
          </v-card-title>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="2" class="align-self-center">
          <v-btn
            v-if="isAvailablePremium()"
            color="primary"
            :disabled="isError"
            @click="loading ? '' : update()"
            >保存する</v-btn
          >
        </v-col>
        <v-col col="12" class="py-0">
          <v-alert v-if="isError" dense outlined type="error">
            エラーが発生しました。管理者までお問い合わせ下さい。
          </v-alert>
        </v-col>
      </v-row>
      <v-row style="position: relative">
        <v-overlay
          :absolute="true"
          opacity="0.25"
          color="#212121"
          :value="!isAvailablePremium()"
          class="mx-4"
        >
        </v-overlay>
        <v-col cols="12" class="px-10 pt-0">
          <v-tabs
            color="success"
            hide-arrows
            flat
            fixed-tabs
            hide-slider
            height="68"
            class="tabs-status"
          >
            <v-tab
              @click="changeTarget('descriptionForSmartPhone')"
              :disabled="!isAvailablePremium()"
              class="px-0 mr-2"
              style="border-bottom: solid 8px #92b125"
            >
              <v-icon color="success">mdi-cellphone-text</v-icon>
              <span class="d-inline-block success--text font-weight-bold ml-1">
                スマートフォン用商品説明文
              </span>
            </v-tab>
            <v-tab
              @click="changeTarget('descriptionBySalesMethod')"
              :disabled="!isAvailablePremium()"
              class="px-0 mx-2"
              style="border-bottom: solid 8px #92b125"
            >
              <v-icon color="success" style="transform: rotateX(180deg)"
                >mdi-comment-text-outline</v-icon
              >
              <span
                class="success--text font-weight-bold ml-1"
                style="font-size: 14px"
                >PC用販売説明文</span
              >
            </v-tab>
            <v-tab
              @click="changeTarget('descriptionForPC')"
              :disabled="!isAvailablePremium()"
              class="px-0 ml-2"
              style="border-bottom: solid 8px #92b125"
            >
              <v-icon color="success" style="transform: rotateY(180deg)"
                >mdi-comment-text-outline</v-icon
              >
              <span
                class="success--text font-weight-bold ml-1"
                style="font-size: 14px"
                >PC用商品説明文</span
              >
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row class="mb-4" style="position: relative">
        <v-overlay
          :absolute="true"
          :opacity="!isAvailablePremium() ? '0.25' : '0.8'"
          :color="!isAvailablePremium() ? '#212121' : '#fff'"
          :value="!isAvailablePremium() || loading || isError"
          class="mx-4"
        >
        </v-overlay>
        <v-col cols="5" class="pl-10 pr-0">
          <div
            class="grey lighten-5"
            style="min-height: 100%; border: solid 1px #9d9d9d !important"
          >
            <p class="grey darken-1 text-center py-3 mb-0">
              <span class="white--text font-weight-bold">未使用システム</span>
            </p>
            <!-- 未使用システム -->
            <draggable
              v-model="editUnused"
              class="pa-4"
              style="min-height: 400px"
              group="draggable_tools"
              :move="checkMove"
            >
              <div
                v-for="(element, index) in editUnused"
                :key="index"
                class="sortable_item white rounded-lg pa-3 mb-3"
                :class="isFixed(element.tool_id) ? 'fixed' : ''"
                style="border: solid 1px #d0d0d0 !important"
                @drag="alert(element.tool_id)"
                draggable="true"
              >
                <v-row>
                  <v-col
                    cols="3"
                    class="align-self-center"
                    style="font-size: 14px"
                  >
                    <p class="ma-0">
                      {{
                        tools.find((value) => value.id == element.tool_id).name
                      }}
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      <span class="mx-2 label-text" style="font-size: 12px"
                        >優先順位</span
                      >
                      <v-combobox
                        v-model="element.priority"
                        dense
                        outlined
                        hide-details="false"
                        style="font-size: 13px"
                        :items="priorityChoices"
                        :disabled="true"
                      ></v-combobox>
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <div>
                      <span class="mx-2 label-text" style="font-size: 12px"
                        >デザイン</span
                      >
                      <v-select
                        v-model="element.layout_id"
                        :items="
                          tools.find((value) => value.id == element.tool_id)
                            .layouts
                        "
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        :disabled="isDisable(element.tool_id)"
                        hide-details="false"
                        style="font-size: 13px"
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </draggable>
            <!-- / 未使用システム -->
          </div>
        </v-col>

        <v-col cols="2">
          <div class="text-center d-flex flex-column" style="height: 100%">
            <v-icon color="gray" class="mt-auto" x-large
              >mdi-gesture-swipe-horizontal</v-icon
            >
            <div class="mt-2 mb-auto" style="font-size: 12px">
              ドラッグ&amp;ドロップで<br />項目を移動出来ます
            </div>
          </div>
        </v-col>

        <v-col cols="5" class="pr-10 pl-0">
          <p class="light-green text-center py-3 mb-0">
            <span class="white--text font-weight-bold"
              >おまかせ機能レイアウト</span
            >
          </p>
          <div class="pa-4 pt-0" style="border: solid 1px #9d9d9d !important">
            <!-- 商品説明文上部 -->
            <p
              class="grey--text text--darken-1 text-center grey lighten-5 py-2 mx-n4 rounded-sm"
              style="font-size: 13px"
            >
              御社作成の上に表示
            </p>
            <draggable
              v-model="editTop"
              class="draggable_area"
              group="draggable_tools"
              @start="onDragStart"
              @end="onDragEnd"
            >
              <div
                v-for="(element, index) in editTop"
                :key="index"
                class="sortable_item white rounded-lg pa-3 mb-3"
                style="border: solid 1px #d0d0d0 !important"
              >
                <v-row>
                  <v-col
                    cols="3"
                    class="align-self-center"
                    style="font-size: 14px"
                  >
                    <p class="ma-0">
                      {{
                        tools.find((value) => value.id == element.tool_id).name
                      }}
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      <span class="mx-2 label-text" style="font-size: 12px"
                        >優先順位</span
                      >
                      <v-combobox
                        v-model="element.priority"
                        dense
                        outlined
                        hide-details="false"
                        style="font-size: 13px"
                        :items="priorityChoices"
                        @change="handlePriorityChange(element)"
                      ></v-combobox>
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <div>
                      <span class="mx-2 label-text" style="font-size: 12px"
                        >デザイン</span
                      >
                      <v-select
                        v-model="element.layout_id"
                        :items="
                          tools.find((value) => value.id == element.tool_id)
                            .layouts
                        "
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        :disabled="isDisable(element.tool_id)"
                        hide-details="false"
                        style="font-size: 13px"
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </draggable>
            <!-- / 商品説明文上部 -->

            <div class="grey lighten-2 text-center pa-3 mx-n4 mt-3">
              <span class="black--text">御社作成<br />商品説明文</span>
            </div>

            <!-- 商品説明文下部 -->
            <p
              class="grey--text text--darken-1 text-center grey lighten-5 py-2 mx-n4 rounded-sm"
              style="font-size: 13px"
            >
              御社作成の下に表示
            </p>
            <draggable
              v-model="editBtm"
              class="draggable_area"
              group="draggable_tools"
              @start="onDragStart"
              @end="onDragEnd"
            >
              <div
                v-for="(element, index) in editBtm"
                :key="index"
                class="sortable_item white rounded-lg pa-3 mb-3"
                style="border: solid 1px #d0d0d0 !important"
              >
                <v-row>
                  <v-col
                    cols="3"
                    class="align-self-center"
                    style="font-size: 14px"
                  >
                    <p class="ma-0">
                      {{
                        tools.find((value) => value.id == element.tool_id).name
                      }}
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      <span class="mx-2 label-text" style="font-size: 12px"
                        >優先順位</span
                      >
                      <v-combobox
                        v-model="element.priority"
                        dense
                        outlined
                        hide-details="false"
                        style="font-size: 13px"
                        :items="priorityChoices"
                        @change="handlePriorityChange(element)"
                      ></v-combobox>
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <div>
                      <span class="mx-2 label-text" style="font-size: 12px"
                        >デザイン</span
                      >
                      <v-select
                        v-model="element.layout_id"
                        :items="
                          tools.find((value) => value.id == element.tool_id)
                            .layouts
                        "
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        :disabled="isDisable(element.tool_id)"
                        hide-details="false"
                        style="font-size: 13px"
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </draggable>
            <!-- / 商品説明文下部 -->
          </div>
        </v-col>
      </v-row>
    </v-col>
    <floating-notification-alert
      :open="finished_alert"
      @close="finished_alert = false"
    >
      <v-icon color="success">mdi-checkbox-marked</v-icon>
      <span color="success">設定を保存しました。</span>
    </floating-notification-alert>
    <v-snackbar v-model="isAlert" timeout="2000" top>
      個別でOFFになっているため、使用できません
      <template v-slot:action="{ attrs }">
        <v-btn color="gray" text v-bind="attrs" @click="isAlert = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import FloatingNotificationAlert from "../../../../components/alert/FloatingNotificationAlert.vue";
export default {
  props: ["user"],
  components: {
    draggable,
    FloatingNotificationAlert,
  },
  data() {
    return {
      loading: false,
      isError: false,
      isAlert: false,
      finished_alert: false,
      operation_status_tab: "descriptionForSmartPhone",
      tools: [],
      arrangements: [],
      default_arrangements: [],
      editUnused: [],
      editTop: [],
      editBtm: [],
      sendArray: {
        arrangements: {
          shop_id: null,
          target: "",
          arrangements_tools: [],
        },
        delete_arrangement_tool_ids: [],
      },
      priorityChoices: [],
      order: [],
    };
  },
  created() {
    this.getLayout();
    this.adjustPriority();
    this.setPriorityChoices();
  },
  watch: {
    editUnused: {
      deep: true,
      handler(newEditUnused) {
        // 優先順位のセレクトボックス内の選択肢を更新
        newEditUnused.forEach((element) => {
          element.priority = null;
        });
        this.setPriorityChoices();
      },
    },
    editTop: {
      deep: true,
      handler(newEditTop) {
        // 優先順位のセレクトボックス内の選択肢を更新
        this.setPriorityChoices();
        const emptyPriorityElements = newEditTop.filter(
          (element) => element.priority === null
        );
        if (emptyPriorityElements.length > 0) {
          const maxPriorityTop = Math.max(
            ...newEditTop.map((element) => element.priority)
          );
          const maxPriorityBtm = Math.max(
            ...this.editBtm.map((element) => element.priority)
          );
          const maxPriority = Math.max(maxPriorityTop, maxPriorityBtm);
          emptyPriorityElements.forEach((element) => {
            element.priority = maxPriority + 1;
          });
          this.$forceUpdate();
        }
      },
    },
    editBtm: {
      deep: true,
      handler(newEditBtm) {
        // 優先順位のセレクトボックス内の選択肢を更新
        this.setPriorityChoices();
        const emptyPriorityElements = newEditBtm.filter(
          (element) => element.priority === null
        );
        if (emptyPriorityElements.length > 0) {
          const maxPriorityBtm = Math.max(
            ...newEditBtm.map((element) => element.priority)
          );
          const maxPriorityTop = Math.max(
            ...this.editTop.map((element) => element.priority)
          );
          const maxPriority = Math.max(maxPriorityBtm, maxPriorityTop);
          emptyPriorityElements.forEach((element) => {
            element.priority = maxPriority + 1;
          });
          this.$forceUpdate();
        }
      },
    },
  },
  methods: {
    async getLayout() {
      this.loading = true;
      this.editTop = [];
      this.editBtm = [];
      const TARGET = this.operation_status_tab;

      // toolsを取得
      const tools = await axios.get(`/api/v1/tools/`, {
        params: { target: TARGET },
      });
      this.tools = tools.data.tools;

      // arrangementsを取得
      axios
        .get(`/api/v1/arrangements/shop/${TARGET}`)
        .then((res) => {
          this.arrangements = res.data.arrangements;
          this.default_arrangements = res.data.default_arrangements;
          let unused = [];
          let editTop = [];
          let editBtm = [];
          let usables = [];
          const self = this;
          if (this.arrangements) {
            // 登録済み時の処理
            usables = this.arrangements.arrangements_tools;
          } else {
            // 未登録時の処理
            usables = this.default_arrangements.arrangements_tools;
          }

          usables.forEach(function (ele) {
            const inTools = self.tools.some((data) => data.id === ele.tool_id);
            if (inTools) {
              if (ele.position_in_description == "top") {
                editTop.push(ele);
              } else if (ele.position_in_description == "bottom") {
                editBtm.push(ele);
              }
            }
          });

          // 未使用システム一覧の作成
          this.tools.forEach(function (ele) {
            const disabled = self.isFixed(ele.id);
            const inEditTop = editTop.some((data) => data.tool_id === ele.id);
            const inEditBtm = editBtm.some((data) => data.tool_id === ele.id);
            const item = {
              id: null,
              arrangement_id: null,
              priority: unused.length + 1,
              tool_id: ele.id,
              layout_id: ele.layouts[0].id,
              position_in_description: "",
            };
            if (!inEditTop && !inEditBtm && !disabled) {
              unused.push(item);
            } else if (disabled) {
              if (inEditTop) {
                editTop = editTop.filter((i) => i.tool_id !== ele.id);
              }
              if (inEditBtm) {
                editBtm = editBtm.filter((i) => i.tool_id !== ele.id);
              }
              unused.push(item);
            }
          });
          this.editUnused = unused.sort((a, b) => a.priority - b.priority);
          this.editTop = editTop.sort((a, b) => a.order - b.order);
          this.editBtm = editBtm.sort((a, b) => a.order - b.order);

          // ラクイチアピールの値を設定
          const rakuichiLayout = self.user.full_auto_rakuichi_layout;
          if (rakuichiLayout) {
            this.replaceTools(8, rakuichiLayout);
          }
          // 商品レビューの値を設定
          const reviewLayout = self.user.full_auto_review_layout;
          if (reviewLayout) {
            this.replaceTools(11, reviewLayout);
          }
          // 商品レビューの値を設定
          const favoriteLayout = self.user.full_auto_favorite_layout;
          if (favoriteLayout) {
            this.replaceTools(12, favoriteLayout);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.sendArray.arrangements.target = this.operation_status_tab;
      this.sendArray.delete_arrangement_tool_ids = this.editUnused.map(
        (item) => item.id
      );

      // order の値を入れる
      for (let i = 0; i < this.editTop.length; i++) {
        this.editTop[i].position_in_description = "top";
        this.editTop[i].order = i + 1;
      }
      for (let i = 0; i < this.editBtm.length; i++) {
        this.editBtm[i].position_in_description = "bottom";
        this.editBtm[i].order = i + 1;
      }
      const tools = [...this.editTop].reverse().concat(this.editBtm);

      this.sendArray.arrangements.arrangements_tools = tools;
      const data = this.sendArray;
      axios
        .post("/api/v1/arrangements/update", data)
        .then((res) => {
          if (res.data.error) {
            this.isError = true;
          } else {
            this.finished_alert = true;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    changeTarget(tgt) {
      this.operation_status_tab = tgt;
      this.getLayout();
    },
    isAvailablePremium() {
      return this.user["is_available_premium?"];
    },
    checkMove(e) {
      return !this.isFixed(e.draggedContext.element.tool_id);
    },
    isFixed(id) {
      const fixed =
        (id == 8 && !this.user.is_full_auto_rakuichi_on) ||
        (id == 11 && !this.user.is_full_auto_review_on) ||
        (id == 6 && !this.user.is_able_to_fetch_order);
      return fixed;
    },
    isDisable(id) {
      //ラクイチアピールと商品レビューとお気に入りはセレクトボックスを非活性にする
      const disables = [8, 11, 12];
      return disables.includes(id);
    },
    replaceTools(id, layout) {
      const self = this;
      const editArrays = [this.editUnused, this.editTop, this.editBtm];
      editArrays.forEach(function (editArray) {
        const target = editArray.find((data) => data.tool_id === id);
        if (target) {
          target.layout_id = layout.id;
          const tool = self.tools.find((data) => data.id === id);
          tool.layouts.push(layout);
        }
      });
    },
    alert(id) {
      if (this.isFixed(id) && !this.isAlert) {
        this.isAlert = true;
      }
    },

    onDragStart() {
      // 項目移動のドラッグが開始したときの処理
      this.drag = true;
    },

    onDragEnd() {
      // 項目移動のドラッグが終了したときの処理
      this.drag = false;
      this.adjustPriority();
    },

    adjustPriority() {
      // 優先順位セレクトボックスの連続性を保つため
      const allUseElements = [...this.editTop, ...this.editBtm];
      allUseElements.sort((a, b) => a.priority - b.priority);

      allUseElements.forEach((element, index) => {
        element.priority = index + 1;
      });
    },

    setPriorityChoices() {
      // 優先順位セレクトボックスの選択肢をセット
      const editTopCount = this.editTop.length;
      const editBtmCount = this.editBtm.length;
      const totalElements = editTopCount + editBtmCount;

      const selectedPriorities = [...this.editTop, ...this.editBtm]
        .filter((element) => element.priority !== null)
        .map((element) => element.priority)
        .sort((a, b) => a - b);

      this.priorityChoices = [];

      for (let i = 1; i <= totalElements; i++) {
        if (selectedPriorities.includes(i)) {
          let newPriority = i;
          while (selectedPriorities.includes(newPriority)) {
            newPriority++;
          }
          selectedPriorities.push(newPriority);
        }

        this.priorityChoices.push(i);
      }
    },

    handlePriorityChange(changedElement) {
      // 既にセレクトされている優先順位 が 別の要素の優先順位 としてセレクトされたとき 入れ替える
      const newSelectedPriority = changedElement.priority;
      const allUseElements = [...this.editTop, ...this.editBtm];
      const samePriorityElements = allUseElements.filter(
        (element) => element.priority === newSelectedPriority
      );

      // 同じ優先順位を持つ要素の優先順位を0に設定
      samePriorityElements.forEach((element) => {
        if (element !== changedElement) {
          element.priority = 0;
        }
      });

      const allUsePriorityValues = Array.from(
        { length: allUseElements.length },
        (_, i) => i + 1
      );
      const alreadySelectedPriorities = allUseElements.map(
        (element) => element.priority
      );
      const unselectedPriorities = allUsePriorityValues.filter(
        (priority) => !alreadySelectedPriorities.includes(priority)
      );

      unselectedPriorities.forEach((priority) => {
        const zeroPriorityElement = allUseElements.find(
          (element) => element.priority === 0
        );
        if (zeroPriorityElement) {
          zeroPriorityElement.priority = priority;
        }
      });

      this.setPriorityChoices();
    },
  },
};
</script>

<style>
.v-application .draggable_area {
  min-height: 150px;
}
.v-application .sortable_item {
  cursor: grab;
}
.v-application .sortable_item:active {
  cursor: grabbing;
}
.v-application .sortable_item.sortable-ghost {
  opacity: 0.5;
}
.v-application .sortable_item.fixed {
  opacity: 0.5;
}
</style>
<style lang="sass">
.tabs-status{
  .v-tab{
    opacity: 0.4;
    &.v-tab--active{
      opacity: 1;
    }
  }
}
</style>
