<template>
  <v-card rounded="xl" :class="isInactive ? 'inactive-card' : 'active-card'">
    <v-card-title class="justify-space-between text-body-1">
      <div class="d-flex align-center">
        <v-icon size="40" :color="mainColor" class="mr-4">mdi-gift-open-outline</v-icon>
        <v-icon size="24" :color="mainColor" class="mr-1">mdi-cellphone-text</v-icon>
        <span>スマートフォン用商品説明文</span>
      </div>
      <div v-if="user.updating_item_info_started_at">
        更新日時: <span v-if="!isInactive">{{ time_ymdt_format(user.updating_item_info_started_at) }}</span>
      </div>
    </v-card-title>
    <v-divider />
    <operation-item-card-main
      :description-key="descriptionKey"
      :full-auto-column-name="fullAutoColumnName"
      :main-color="mainColor"
      :is-inactive="isInactive"
    />

    <InactiveLayer v-if="isInactive" />
  </v-card>
</template>

<script>
import timeUtilsMixin from "../../../../packs/mixins/time_utils";
import InactiveLayer from "../InactiveLayer.vue";
import OperationItemCardMain from "./Main.vue";
export default {
  name: "OperationItemCardForSmartPhone",
  components: {
    InactiveLayer,
    OperationItemCardMain
  },
  mixins: [timeUtilsMixin],

  data: () => ({
    descriptionKey: 'ForSmartPhone',
    fullAutoColumnName: 'is_full_auto_on',
    mainColor: '#00736D'
  }),

  computed: {
    user() {
      return this.$store.state.user;
    },

    isInactive() {
      return this.$store.getters.isSilverContentsPlan;
    }
  }
}
</script>
