<template>
  <div>
    <v-row
      justify="center"
      class="mt-6"
      :class="!selectMstShopPagePartId ? 'mb-6' : ''"
    >
      <v-col cols="12">
        <v-row>
          <v-col>
            <main-color-rounded-subtitle>パーツ選択</main-color-rounded-subtitle>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-radio-group
              v-model="selectMstShopPagePartId"
              class="mt-0 pt-0"
              mandatory
              hide-details
            >
              <div class="d-flex justify-space-around">
                <v-radio :value="0" style="display: none" />
                <label>
                  <v-radio :value="shopPagePart.SMALL_IMAGE" />
                  <div
                    style="
                      padding: 10px 30px;
                      border: 1px solid rgb(238, 238, 238);
                    "
                  >
                    <v-img
                      src="images/widget_card_image_small.png"
                      max-width="56"
                      height="56"
                      alt="小画像"
                    />
                    <div class="text-center">小画像</div>
                  </div>
                </label>
                <label>
                  <v-radio :value="shopPagePart.LARGE_IMAGE" />
                  <div
                    style="
                      padding: 10px 30px;
                      border: 1px solid rgb(238, 238, 238);
                    "
                  >
                    <v-img
                      src="images/widget_card_image_large.png"
                      max-width="56"
                      height="56"
                      alt="大画像"
                    />
                    <div class="text-center">大画像</div>
                  </div>
                </label>
                <label>
                  <v-radio :value="shopPagePart.ITEM" />
                  <div
                    style="
                      padding: 10px 30px;
                      border: 1px solid rgb(238, 238, 238);
                    "
                  >
                    <v-img
                      src="images/widget_card_item.png"
                      max-width="56"
                      height="56"
                      alt="商品"
                    />
                    <div class="text-center">商品</div>
                  </div>
                </label>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="selectMstShopPagePartId > 0">
      <v-row justify="center" class="mt-6">
        <v-col cols="6">
          <v-row>
            <v-col>
              <black-rounded-subtitle>レイアウト</black-rounded-subtitle>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="selectDisplayFormat"
                class="mt-0 pt-0"
                mandatory
                hide-details
              >
                <div class="d-flex">
                  <label class="d-flex">
                    <v-radio id="landscape" value="landscape" />
                    <div>
                      <v-img
                        src="images/horizontal.bmp"
                        max-width="56"
                        height="56"
                        alt="横スクロール"
                      />
                      <div class="mt-2">横スクロール</div>
                    </div>
                  </label>
                  <label class="d-flex ml-6">
                    <v-radio id="portrait" value="portrait" />
                    <div>
                      <v-img
                        src="images/vertical.bmp"
                        max-width="56"
                        height="56"
                        alt="縦配置"
                      />
                      <div class="mt-2">縦配置</div>
                    </div>
                  </label>
                </div>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <template
            v-if="
              selectMstShopPagePartId === shopPagePart.LARGE_IMAGE &&
              selectDisplayFormat === 'landscape'
            "
          >
            <v-row>
              <v-col>
                <black-rounded-subtitle
                  >横スクロール　自動回転</black-rounded-subtitle
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-radio-group
                  v-model="selectLandscapeScroll"
                  class="mt-0 pt-0"
                  mandatory
                  hide-details
                >
                  <div class="d-flex justify-space-around">
                    <v-radio label="あり" :value="true" />
                    <v-radio label="なし" :value="false" />
                  </div>
                </v-radio-group>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col>
              <black-rounded-subtitle>表示商品数</black-rounded-subtitle>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="selectSize"
                :items="sizeItems"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <v-col>
          <black-rounded-subtitle>表示箇所を選択する</black-rounded-subtitle>
        </v-col>
      </v-row>
      <template v-if="isMultiple">
        <v-radio-group
          v-model="position"
          mandatory
          hide-details
        >
          <v-radio value="" style="display: none" />
          <v-row justify="center">
            <v-col cols="6">
              <label>
                <div class="pa-5 cursor-pointer" :style="position === 'top' ? 'border: 3px solid #004986' : 'border: 3px solid #eee'">
                  <v-img src="images/shop_page/shop_contents/show_position_top.png" height="170" />
                  <v-radio value="top" label="上部" class="mt-2" />
                </div>
              </label>
            </v-col>
            <v-col cols="6">
              <label>
                <div class="pa-5 cursor-pointer" :style="position === 'bottom' ? 'border: 3px solid #004986' : 'border: 3px solid #eee'">
                  <v-img src="images/shop_page/shop_contents/show_position_bottom.png" height="170" />
                  <v-radio value="bottom" label="下部" class="mt-2" />
                </div>
              </label>
            </v-col>
          </v-row>
        </v-radio-group>
      </template>
      <template v-else>
        <v-row justify="center" v-if="!isUpdate">
          <v-col cols="9">
            <p>
              一番下までスクロールしてください。表示されたパーツの表示箇所を↑↓で移動できます。
            </p>
          </v-col>
        </v-row>
        <div
          ref="scrollArea"
          class="my-6 mx-auto pa-4"
          style="
            width: 460px;
            height: 440px;
            overflow-y: auto;
            border: 3px solid #eee;
          "
        >
          <v-row class="pa-2">
            <v-col v-for="(part, index) in parts" :key="part.id" cols="12" ma="2">
              <v-sheet
                class="d-flex"
                :height="
                  part.shop_page_system_id === shopPageSystem.id
                    ? parts.length > 1
                      ? 232
                      : 220
                    : 32
                "
              >
                <v-row
                  v-if="part.shop_page_system_id !== shopPageSystem.id"
                  class="ma-0 pt-1 pl-2 grey lighten-3"
                >
                  {{ part.name }}
                </v-row>
                <v-row
                  v-else
                  class="ma-0"
                  style="border: 6px solid #2a8cc7; background-color: #fff"
                >
                  <div class="pt-1 pl-2">
                    <span>{{
                      `${shopPageSystem.name}（${getPartName(
                        selectMstShopPagePartId
                      )}パーツ）`
                    }}</span>
                  </div>
                  <div
                    v-if="selectDisplayFormat !== 'portrait'"
                    class="d-flex flex-grow-1"
                    style="overflow-x: scroll; width: 400px"
                  >
                    <div v-for="i in selectSize" :key="'count-' + i">
                      <v-sheet
                        class="ma-2 pt-1 pl-2"
                        color="#cacaca"
                        height="150"
                        :width="isLargeImage ? 316 : 150"
                      >
                        {{ i }}
                      </v-sheet>
                    </div>
                  </div>
                  <div
                    v-else
                    class="d-flex flex-wrap justify-center mx-auto mb-2"
                    style="overflow-y: scroll; width: 400px; max-height: 158px"
                  >
                    <div v-for="i in selectSize" :key="'count-' + i">
                      <v-sheet
                        class="ma-2 pl-2"
                        color="#cacaca"
                        height="150"
                        :width="isLargeImage ? 316 : 150"
                      >
                        {{ i }}
                      </v-sheet>
                    </div>
                  </div>

                  <div
                    v-if="parts.length > 1"
                    class="mt-auto ml-auto"
                    style="margin: 0px -6px 0px 0px"
                  >
                    <v-btn
                      color="primary"
                      x-small
                      tile
                      fab
                      @click="sort(index, 0)"
                    >
                      <v-icon>mdi-chevron-double-up</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      x-small
                      tile
                      fab
                      @click="sort(index, index - 1)"
                    >
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      x-small
                      tile
                      fab
                      @click="sort(index, index + 1)"
                    >
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      x-small
                      tile
                      fab
                      @click="sort(index, parts.length - 1)"
                    >
                      <v-icon>mdi-chevron-double-down</v-icon>
                    </v-btn>
                  </div>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </div>
      </template>
      <v-row class="pa-6">
        <v-col>
          <div class="d-flex">
            <v-btn
              x-large
              class="flex-grow-0 grey white--text font-weight-bold"
              @click="$emit('close')"
              >戻る</v-btn
            >
            <v-btn
              x-large
              color="primary"
              class="flex-grow-1 ml-4 text-h6 font-weight-bold"
              @click="update"
              >更新する</v-btn
            >
          </div>
          <div v-if="isUpdate" class="mt-4">
            <v-btn
              x-large
              block
              color="#e53935"
              class="white--text text-h6 font-weight-bold"
              @click="remove"
              >取り外す</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { SHOP_PAGE_PART } from "../../../../const";
import BlackRoundedSubtitle from "./BlackRoundedSubtitle.vue";
import MainColorRoundedSubtitle from "./MainColorRoundedSubtitle.vue";

const OTHER_PART_HEIGHT = 56;

export default {
  name: "design-selection",
  mixins: [],
  components: {
    BlackRoundedSubtitle,
    MainColorRoundedSubtitle,
  },
  props: {
    isMultiple: { type: Boolean, default: false },
    shopPageLayouts: { type: Array },
    shopPageLayout: { type: Object },
    shopPageSystem: { type: Object },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isUpdate() {
      if (this.isMultiple) return false;
      return this.shopPageLayout["widget_count_" + this.shopPageSystem.code] > 0;
    },
    shopPagePart() {
      return SHOP_PAGE_PART[this.shopPageSystem.code.toUpperCase()];
    },
    isLargeImage() {
      return this.selectMstShopPagePartId === this.shopPagePart.LARGE_IMAGE;
    },
  },
  watch: {
    selectMstShopPagePartId(_newValue, oldValue) {
      if (oldValue === 0) {
        this.$nextTick(() => {
          if (this.$refs.scrollArea) {
            this.$refs.scrollArea.scrollTop = this.$refs.scrollArea.scrollHeight;
          }
        });
      }
    }
  },
  async mounted() {
    if (this.isMultiple) return;

    this.parts = await this.getParts(this.shopPageLayout.id);

    const index = this.parts.findIndex(
      (part) => part.shop_page_system_id === this.shopPageSystem.id
    );
    const target = this.parts[index];
    if (target) {
      this.selectMstShopPagePartId = target.mst_part_id;
      this.selectDisplayFormat = target.display_format;
      this.selectSize = target.size;
    } else {
      this.parts.push({
        id: null,
        order: this.parts.length,
        name: this.shopPageSystem.name,
        shop_page_system_id: this.shopPageSystem.id,
      });
    }

    this.$nextTick(() => {
      if (this.$refs.scrollArea) {
        this.$refs.scrollArea.scrollTop = OTHER_PART_HEIGHT * index;
      }
    });
  },
  data() {
    return {
      selectMstShopPagePartId: 0,
      selectDisplayFormat: "landscape",
      selectLandscapeScroll: false,
      selectSize: 12,
      sizeItems: [
        { text: "2", value: 2 },
        { text: "4", value: 4 },
        { text: "8", value: 8 },
        { text: "10", value: 10 },
        { text: "12（最大）", value: 12 },
      ],
      parts: [],
      position: 'top'
    };
  },
  methods: {
    async getParts(shopPageLayoutId) {
      const params = {
        shop_page_layout_id: shopPageLayoutId,
      };

      const response = await axios.get("/api/v1/shop_page_layouts/get_parts", {
        params,
      });

      return response.data.parts.sort((a, b) => a.order - b.order);
    },
    getPartName(mstPartId) {
      if (
        mstPartId === SHOP_PAGE_PART.NEW.ITEM ||
        mstPartId === SHOP_PAGE_PART.RESALE.ITEM
      ) {
        return "商品";
      } else if (
        mstPartId === SHOP_PAGE_PART.NEW.SMALL_IMAGE ||
        mstPartId === SHOP_PAGE_PART.RESALE.SMALL_IMAGE
      ) {
        return "小画像";
      } else if (
        mstPartId === SHOP_PAGE_PART.NEW.LARGE_IMAGE ||
        mstPartId === SHOP_PAGE_PART.RESALE.LARGE_IMAGE
      ) {
        return "大画像";
      }
    },
    sort(fromIndex, toIndex) {
      if (toIndex < 0) return;
      if (toIndex >= this.parts.length) return;
      if (fromIndex === toIndex) return;

      const cloneParts = [...this.parts];

      if (toIndex === 0) {
        const target = cloneParts.splice(fromIndex, 1);
        cloneParts.unshift(target[0]);
      } else if (toIndex === this.parts.length - 1) {
        const target = cloneParts.splice(fromIndex, 1);
        cloneParts.push(target[0]);
      } else {
        [cloneParts[toIndex], cloneParts[fromIndex]] = [
          this.parts[fromIndex],
          this.parts[toIndex],
        ];
      }

      this.parts = cloneParts;
    },
    async update() {
      if (this.isMultiple) {
        await this.updateMultiple();
        return;
      }

      const index = this.parts.findIndex(
        (part) => part.shop_page_system_id === this.shopPageSystem.id
      );
      const target = this.parts[index];

      const params = {
        shop_page_layout_id: this.shopPageLayout.id,
        shop_page_system_id: this.shopPageSystem.id,
        shop_page_part_id: target.id,
        mst_shop_page_part_id: this.selectMstShopPagePartId,
        display_format: this.selectDisplayFormat,
        size: this.selectSize,
        order: index + 1,
      };

      // 大画像かつ自動回転ありの場合
      if (this.isLargeImage && this.selectLandscapeScroll) {
        params.display_format = "landscape_scroll";
      }

      this.$emit("update", params);
    },
    async updateMultiple() {
      const params = [];
      for (let i = 0; i < this.shopPageLayouts.length; i++) {
        const layout = this.shopPageLayouts[i];
        const parts = await this.getParts(layout.id);

        const index = parts.findIndex(
          (part) => part.shop_page_system_id === this.shopPageSystem.id
        );
        const target = parts[index];

        const param = {
          shop_page_layout_id: layout.id,
          shop_page_system_id: this.shopPageSystem.id,
          shop_page_part_id: target ? target.id : null,
          mst_shop_page_part_id: this.selectMstShopPagePartId,
          display_format: this.selectDisplayFormat,
          size: this.selectSize,
          order: this.position === 'top' ? 1 : target ? parts.length : parts.length + 1,
        };

        // 大画像かつ自動回転ありの場合
        if (this.isLargeImage && this.selectLandscapeScroll) {
          param.display_format = "landscape_scroll";
        }

        params.push(param);
      }

      this.$emit("update", params);
    },
    remove() {
      const target = this.parts.find(
        (part) => part.shop_page_system_id === this.shopPageSystem.id
      );
      this.$emit("remove", target.id);
    },
  },
};
</script>

<style scoped></style>
