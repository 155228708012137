<template>
  <v-card rounded="xl" :class="isInactive ? 'inactive-card' : 'active-card'">
    <v-card-title class="justify-space-between text-body-1">
      <div class="d-flex align-center">
        <v-icon size="40" :color="mainColor" class="mr-4">mdi-clipboard-outline</v-icon>
        <span>コンテンツページ自動更新</span>
      </div>
      <div v-if="user.shop_page_updated_at">
        更新日時: <span v-if="!isInactive">{{ time_ymdt_format(user.shop_page_updated_at) }}</span>
      </div>
    </v-card-title>
    <v-divider />
    <div
      class="card-content d-flex align-center"
      style="height:calc(100% - 72px);"
    >
      <div class="d-flex flex-column justify-center align-center" style="width:35%;max-width:256px;">
        <v-progress-circular
          :rotate="-90"
          :size="120"
          :width="16"
          :value="getPercentage(runningCount, allCount)"
          :color="mainColor"
          class="mb-6"
        >
          <span class="text-h5 black--text">
            {{ getPercentage(runningCount, allCount) }}%
          </span>
        </v-progress-circular>

        <div class="d-flex mb-8">
          <div class="text-center">
            <div class="text-body-2 mb-2">
              <v-icon :color="mainColor" class="mr-1">mdi-checkbox-marked-circle</v-icon>
              <span class="text-body-2">稼働中</span>
            </div>
            <div class="text-h5">{{ Number(runningCount).toLocaleString() }} / {{ Number(allCount).toLocaleString() }}</div>
          </div>
        </div>

        <div>
          <div class="mb-1">
            <v-icon color="grey" class="mr-1">mdi-check-circle-outline</v-icon>
            <span class="text-body-2">未稼働：{{ Number(allCount - runningCount).toLocaleString() }} ページ</span>
          </div>
          <div class="mb-4">
            <v-icon color="error" class="mr-1">mdi-alert-circle</v-icon>
            <span class="text-body-2">エラー：{{ Number(errorCount).toLocaleString() }} ページ</span>
          </div>
          <div class="text-center">
            <v-chip v-if="errorCount > 0" small color="error" @click="gotoContentsPage">エラーページを確認する</v-chip>
            <v-chip v-else small color="secondary">エラーページを確認する</v-chip>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-center flex-grow-1 pr-8 pl-2">
        <div v-for="(system, i) in shopPageSystems" :key="i" class="mb-8">
          <div class="d-flex text-body-2 grey--text text--darken-2 mb-1">
            <div class="d-flex align-center">
              <v-img
                class="mr-2"
                max-width="24"
                :src="`/images/dashboard/${system.code}_grey.png`"
              ></v-img>
              <span class="">{{ system.name }}</span>
            </div>
            <div class="d-flex flex-grow-1 justify-end align-center">
              <span class="">{{ Number(systemCount(system.code)).toLocaleString() }}ページ</span>
              <span class="ml-2">({{ getPercentage(systemCount(system.code), allCount) }}%)</span>
            </div>
          </div>
          <v-progress-linear
            :color="isInactive ? 'grey' : mainColor"
            :value="getPercentage(systemCount(system.code), allCount)"
          ></v-progress-linear>
        </div>
      </div>
    </div>

    <InactiveLayer v-if="isInactive" />
  </v-card>
</template>

<script>
import axios from "axios";
import timeUtilsMixin from "../../../../packs/mixins/time_utils";
import { PAGE_TYPE_CONTENTS } from "../../../../const";
import InactiveLayer from "../InactiveLayer.vue";
export default {
  name: "OperationPageCardForContents",
  components: {
    InactiveLayer
  },
  mixins: [timeUtilsMixin],

  data: () => ({
    pageType: PAGE_TYPE_CONTENTS,
    mainColor: '#004986',
    shopPageStatus: [],
    shopPageSystems: []
  }),

  created () {
    axios.get("/api/v1/shop_page_layouts/get_page_status/", {
      params: { type: this.pageType }
    }).then((response) => {
      this.shopPageStatus = response.data.shop_page_status;
      this.shopPageSystems = response.data.shop_page_systems;
    });
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    isInactive() {
      return this.$store.getters.isFreePlan || this.$store.getters.isSilverItemPlan;
    },
    allCount() {
      return this.shopPageStatus.length;
    },
    errorCount() {
      return this.shopPageStatus.filter((item) => item.count_error === 1).length;
    },
    runningCount() {
      if (this.isInactive) return 0;
      return this.shopPageStatus.filter((item) => {
        return this.shopPageSystems.filter((system) => item[`count_${system.code}`] > 0).length;
      }).length;
    },
    systemCount() {
      return (systemCode) => {
        return this.shopPageStatus.filter((item) => {
          return item[`count_${systemCode}`] > 0;
        }).length;
      }
    }
  },

  methods: {
    getPercentage(num1, num2) {
      if (num2 === 0) return 0;
      return Math.floor(num1 / num2 * 100);
    },
    gotoContentsPage() {
      this.$router.push({ name: 'ContentsPageLayouts' });
    }
  }
}
</script>
