<template>
  <div>
    <v-text-field
      class="mx-auto"
      style="max-width: 500px"
      v-model="searchText"
      append-icon="mdi-magnify"
      label="ページタイトル・URL"
      single-line
      dense
      hide-details
      outlined
      @click:append="updateSearch"
      @keypress.enter="isEnterReady = true"
      @keyup.enter="onKeyUpEnter"
    ></v-text-field>

    <v-row class="mt-4">
      <v-col cols="10" offset="2">
        <v-row class="align-center">
          <v-col cols="3" class="font-weight-medium"
            >URLアクセス設定</v-col
          >
          <v-col cols="9">
            <v-radio-group
              class="block_item my-auto"
              :value="accessStatus"
              dense
              hide-details
              row
              @change="$emit('update:accessStatus', $event)"
            >
              <v-radio label="すべて" value="all" class="mx-1"></v-radio>
              <v-radio
                label="アクセス可能"
                value="public"
                class="mx-1"
              ></v-radio>
              <v-radio
                label="アクセス不可"
                value="unpublic"
                class="mx-1"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="3" class="font-weight-medium">システム</v-col>
          <v-col cols="9">
            <system-select
              :shopPageSystems="shopPageSystems"
              :value="searchShopPageSystemId"
              :dense="true"
              style="width: 200px"
              @selectedSystem="
                $emit('update:searchShopPageSystemId', $event)
              "
            >
            </system-select>
          </v-col>
        </v-row>
        <v-row class="align-center" justify="center">
          <v-col cols="9" offset="3">
            <v-checkbox
              v-model="isOnlyErrorChecked"
              label="エラーページのみ"
              dense
              hide-details
              @change="$emit('update:isOnlyError', $event)"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SystemSelect from "./SystemSelect.vue";

export default {
  components: {
    SystemSelect,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    this.isOnlyErrorChecked = this.isOnlyError;
  },
  data() {
    return {
      isOnlyErrorChecked: false,
      searchText: '',
      isEnterReady: false,
    };
  },
  watch: {
    search(val) {
      this.searchText = val;
    },
    searchText(val) {
      if (!val) {
        this.$emit("update:search", val);
      }
    }
  },
  props: [
    "shopPageSystems",
    "search",
    "accessStatus",
    "searchShopPageSystemId",
    "isOnlyError",
  ],
  methods: {
    onKeyUpEnter() {
      if (this.isEnterReady) {
        this.updateSearch();
        this.isEnterReady = false;
      }
    },
    updateSearch() {
      this.$emit("update:search", this.searchText);
    }
  },
};
</script>

<style scoped>
.block_item {
  display: inline-block;
  vertical-align: middle;
}
</style>
