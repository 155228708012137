<template>
  <v-card rounded="xl">
    <v-card-text class="pa-5">
      <div class="subtitle-1 font-weight-bold">ガイドムービー</div>
      <v-sheet
        class="d-flex align-center mx-auto"
        elevation="0"
        style=""
      >
        <v-btn icon class="mx-4" @click="scrollX(-1000)">
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <div
          ref="scrollArea"
          class="d-flex align-center overflow-x-visible overflow-y-hidden py-4"
          :style="{ scrollBehavior: 'smooth' }"
        >
          <div
            v-for="(item, i) in items"
            :key="i"
            class="cursor-pointer"
            @click="onClickThumbnail(item)"
          >
            <div
              class="ml-3"
              style="max-width: 400px;"
            >
              {{ item.text }}
            </div>
            <div class="mx-3 my-1" style="position: relative;">
              <div style="position: absolute; width: 100%; height: 100%;" />
              <iframe width="430" height="242" :src="`https://www.youtube.com/embed/${item.videoId}`" frameborder="0" referrerpolicy="strict-origin-when-cross-origin"></iframe>
            </div>
          </div>
        </div>
        <v-btn icon class="mx-4" @click="scrollX(720)">
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import { YOUTUBE_MOVIES } from "../../../data/movies";

const windowWidth = 720;
const windowHeight = 500;

export default {
  name: "SliderCard",
  data() {
    return {
      items: [ ...YOUTUBE_MOVIES ]
    };
  },
  methods: {
    scrollX(value) {
      const scrollArea = this.$refs.scrollArea;
      const newScrollPosition = scrollArea.scrollLeft + value;
      scrollArea.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth'
      });
    },
    onClickThumbnail(item) {
      // 中央に表示する
      const top = window.screenTop + (window.innerHeight / 2) - (windowHeight / 2);
      const left = window.screenLeft + (window.innerWidth / 2) - (windowWidth / 2);
      window.open(`/#/movies?id=${item.videoId}`, null, `width=${windowWidth}, height=${windowHeight}, top=${top}, left=${left}`)
    }
  }
};
</script>

<style scoped></style>
