<template>
  <v-card rounded="xl">
    <v-card-text class="pa-5">
      <div class="subtitle-1 font-weight-bold">ニュース</div>
      <div id="facebookFrame" style="width: 100%">
        <iframe
          :src="
            'https://www.facebook.com/plugins/page.php' +
            '?href=https%3A%2F%2Fwww.facebook.com%2Fecup.ecdb' +
            `&width=${window}` +
            '&height=600' +
            '&tabs=timeline' +
            '&show_facepile=false' +
            '&small_header=true'
          "
          width="100%"
          height="600"
          style="border: none; overflow: hidden"
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "NewsCard",
  data: function () {
    return {
      window: "",
    };
  },
  methods: {
    handleResize: function () {
      this.window = document.getElementById("facebookFrame").clientWidth;
    },
  },
  mounted() {
    this.window = document.getElementById("facebookFrame").clientWidth;
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.addEventListener("resize", this.handleResize);
  },
};
</script>
