<template>
  <div class="inactive-layer">
    <div class="only-hover flex-column align-center justify-center">
      <template v-if="isShowOption">
        <span class="text-h6 font-weight-bold white--text mb-2">オプション機能がOFFになっています</span>

        <v-tooltip v-if="isAgencyChild" bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              v-on="{ ...tooltip }"
              dark
              class="text-body-1 px-12"
              color="#92b125"
              :ripple="false"
            >
              オプション機能追加
            </v-btn>
          </template>
          <span>担当販売店までご連絡ください</span>
        </v-tooltip>
        <v-btn
          v-else
          dark
          class="text-body-1 px-12"
          color="#92b125"
          @click="$emit('changeOption')"
        >
          オプション機能追加
        </v-btn>
      </template>
      <template v-else>
        <span class="text-h6 font-weight-bold white--text mb-2">現在のプランではご利用いただけません</span>
        <v-tooltip v-if="isAgencyChild" bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              v-on="{ ...tooltip }"
              dark
              class="text-body-1 px-12"
              color="#2a8cc7"
              :ripple="false"
            >
              プラン変更
            </v-btn>
          </template>
          <span>担当販売店までご連絡ください</span>
        </v-tooltip>
        <v-btn
          v-else
          dark
          class="text-body-1 px-12"
          color="primary"
          @click="showPlanIndexModal = true"
        >
          プラン変更
        </v-btn>
      </template>
    </div>

    <PlanIndexModal
      v-if="showPlanIndexModal"
      @close_this_modal="showPlanIndexModal = false"
    />
  </div>
</template>

<script>
import PlanIndexModal from "../../plan/PlanIndexModal.vue";
export default {
  name: 'InactiveLayer',

  components: {
    PlanIndexModal
  },

  props: {
    isShowOption: { class: Boolean, default: false }
  },

  data() {
    return {
      showPlanIndexModal: false
    }
  },

  computed: {
    isAgencyChild() {
      return this.$store.getters.isAgencyChild;
    }
  }
}
</script>

<style scoped lang="scss">
.inactive-layer {
  background: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: auto;
  border-radius: 16px !important;

  .only-hover {
    display: none;
    height: 100%;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.4);

    .only-hover {
      display: flex;
    }
  }
}
</style>
