<template>
  <v-row class="d-sm-flex align-center">
    <v-col class="align-center" style="display: inline-flex">
      <div
        class="text-body-2 ml-0"
        @click="$emit('uncheck_all')"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="grey"
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="#9FA1A0">mdi-checkbox-blank-off</v-icon>
            </v-btn>
          </template>
          <span>全てのチェックを外す</span>
        </v-tooltip>
      </div>

      <div
        style="border: solid 0.5px #e0e0e0; height: 20px"
        class="mx-3"
      ></div>
      <div
        class="block_item text-body-2"
      >
        {{ checked.length }} ページチェック中
      </div>

      <div
        style="border: solid 0.5px #e0e0e0; height: 20px"
        class="mx-3"
      ></div>
      <div class="block_item text-body-2">
        {{ items_from }}~{{ items_to }} / {{ totalHits }} ページ表示
      </div>
    </v-col>
    <v-spacer></v-spacer>
    <v-col class="align-center" style="display: inline-flex">
      <div class="text-body-2" v-for="(pagePer, i) in pagePers" :key="i">
        <a
          v-if="pagePer != itemsPerPage"
          @click="$emit('update:itemsPerPage', pagePer)"
          style="text-decoration: underline"
        >
          {{ pagePer }}ページ表示
        </a>
        <span class="font-weight-bold" v-else>{{ pagePer }}ページ表示</span>
        <span
          style="border-right:solid 0.5px #E0E0E0;height:20px; mx-auto"
          class="mx-3"
          v-if="i != pagePers.length - 1"
        ></span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  computed: {
    user() {
      return this.$store.state.user;
    },
    items_from() {
      return (this.page -1) * this.itemsPerPage + 1;
    },
    items_to() {
      if(this.page == this.totalPages){
        return this.totalHits
      }else{
        return this.page * this.itemsPerPage;
      }
    },
  },
  props: ["checked", "pagePers", "page", "itemsPerPage", "total", "totalHits", "totalPages"],
  methods: {},
};
</script>

<style scoped></style>
