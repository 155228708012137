<template>
  <v-container fluid class="pa-6" v-if="user">
    <v-row>
      <v-col class="display-1">
        <v-icon left large color="#AC6A00">
          mdi-storefront-outline
        </v-icon>
        {{ name }} ページ一覧
      </v-col>
    </v-row>

    <Main
      @fetchUser="fetchUser"
      @setUser="setUser"
      :name="name"
      :user="user"
      :headers="headers"
    />

    <Guard
      v-if="showGuard"
      @showOptionIndexModal="showOptionIndexModal = true"
      :user="user"
    />

    <OptionIndexModal
      v-if="showOptionIndexModal"
      @close_this_modal="showOptionIndexModal = false"
      :user="user"
    />
  </v-container>
</template>

<script>
import axios from "axios";
axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";

import Main from "./components/Main.vue";
import Guard from "./components/Guard.vue";
import OptionIndexModal from "../option/OptionIndexModal.vue";
import userUtilsMixin from "../../packs/mixins/user_utils";

export default {
  name: "ShopPageLayouts",
  components: { Main, Guard, OptionIndexModal },
  mixins: [userUtilsMixin],
  computed: {
    user() {
      return this.$store.state.user;
    },
    showGuard() {
      return !this.user.shops_options.find((o) => o.option.code === 'shop_top' && o.is_enabled);
    },
  },
  data() {
    return {
      name: "トップページ",
      showOptionIndexModal: false,
      headers: {
        title: {
          name: "ページ管理名称",
          description: "",
          width: "34%",
          sortable: true,
          class: 'bg-gray justify-start'
        },
        remaining_small_image_widget_count: {
          name: "残小画像<br />パーツ数",
          description: "",
          width: "12%",
          sortable: true,
          class: 'bg-gray px-0'
        },
        remaining_large_image_widget_count: {
          name: "残大画像<br />パーツ数",
          description: "",
          width: "12%",
          sortable: true,
          class: 'bg-gray px-0'
        },
        remaining_item_widget_count: {
          name: "残商品<br />パーツ数",
          description: "",
          width: "12%",
          sortable: true,
          class: 'bg-gray px-0'
        },
        updateTime: {
          name: "最終更新日時",
          description: "",
          width: "30%",
          sortable: true,
          class: 'bg-gray px-0'
        }
      },
    };
  },
  async created() {
    this.fetchUser();
  },
  methods: {
    fetchUser() {
      this.fetch_user();
    },
    setUser(user) {
      this.SET_USER(user);
    },
  },
};
</script>
